export const SlidesData = [
  {
    image: require("../../../assets/images/slides/slideshow_1.jpg").default,
  },
  {
    image: require("../../../assets/images/slides/slideshow_3.jpg").default,
  },
  {
    image: require("../../../assets/images/slides/slideshow_4.jpg").default,
  },
];
